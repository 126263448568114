<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in activeItems"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    activeItems() {
      return this.items.filter(item => {
        let ret = true
        if (!this.getUser.admin && item.admin_only) {
          ret = false
        }

        if (item.kyc_min) {
          if (this.getUser.kyc < item.kyc_min) {
            ret = false
          }
        }

        return ret
      })
    },
    ...mapGetters({
      getUser: 'users/user',
    }),
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
