<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />

      <LanguageSwitcher />

    </div>

    <b-navbar-nav
      v-if="render"
      class="nav align-items-center ml-auto"
    >
      <b-nav-item class="border-right px-1">
        <div class="user-nav">
          <p class="user-name font-weight-bolder mb-0">
            USDT {{ user.usd_value }}

            <br>
            <small class="user-status">USDT</small>
          </p>
        </div>
      </b-nav-item>
      <!-- <b-nav-item class="border-right px-1">
        <div class="user-nav">
          <p class="user-name font-weight-bolder mb-0">
            R$ {{ user.brl_value }}
            <br>
            <small class="user-status">BRL</small>
          </p>
        </div>
      </b-nav-item> -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <!-- <span class="user-status">Plano: {{ user.plan_id }}</span> -->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/user.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          to="/carteira"
        >
          <feather-icon
            size="16"
            icon="CreditCardIcon"
            class="mr-50"
          />
          <span>Minha Carteira</span>
        </b-dropdown-item> -->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          to="/perfil"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('user_menu.profile') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="sair()"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('user_menu.exit') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItem, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { userService } from '@/services/user/list'
import { mapGetters } from 'vuex'
import LanguageSwitcher from '@/layouts/components/LanguageSwitcher.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    LanguageSwitcher,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userService: userService(this.$http),
      user: null,
      render: false,
    }
  },
  computed: {
    ...mapGetters({ getUser: 'users/user' }),
  },
  async created() {
    let resultado = await this.userService.getUser(`uuid=${this.getUser.uuid}`)
    this.user = resultado
    await setInterval(async () => {
      resultado = await this.userService.getUser(`uuid=${this.getUser.uuid}`)
      this.user = resultado
    }, 10000)
    this.render = true
  },
  methods: {
    sair() {
      this.$swal({
        title: 'Sair do Sistema?',
        text: 'Deseja realmente efetuar esta ação?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(result => {
        if (result.isConfirmed) {
          localStorage.clear()
          this.$router.push({ name: 'login' })
        }
      })
    },
  },
}
</script>
