export default [
  {
    route: 'dashboard',
    icon: 'HomeIcon',
    kyc_min: 3,
    slug: 'dashboard',
  },
  {
    route: 'deposits',
    icon: 'PlusSquareIcon',
    kyc_min: 3,
    slug: 'deposits',
  },
  {
    route: 'listDeposits',
    icon: 'PlusSquareIcon',
    admin_only: true,
    slug: 'approve_deposits',
  },
  {
    route: 'withdraw',
    icon: 'DollarSignIcon',
    kyc_min: 3,
    slug: 'withdraws',
  },
  {
    route: 'listSaques',
    icon: 'DollarSignIcon',
    admin_only: true,
    slug: 'approve_withdraws',
  },
  {
    route: 'profile',
    icon: 'UserIcon',
    slug: 'profile',
  },
  {
    route: 'users',
    icon: 'UsersIcon',
    admin_only: true,
    slug: 'users',
  },
  {
    route: 'performance',
    icon: 'ActivityIcon',
    admin_only: true,
    slug: 'performance',
  },
]
