const userService = http => {
  const endpoint = `${process.env.VUE_APP_API_BASE_URL}admin/user`
  const endpointAdmin = `${process.env.VUE_APP_API_BASE_URL}admin/user/`

  const get = urlComplementar => {
    // eslint-disable-next-line no-param-reassign
    urlComplementar = urlComplementar === undefined ? '' : urlComplementar
    const url = `${endpoint}/?${urlComplementar}`
    return http.get(url).then(data => data.data)
  }

  const getAdmin = urlComplementar => {
    // eslint-disable-next-line no-param-reassign
    urlComplementar = urlComplementar === undefined ? '' : urlComplementar
    const url = `${endpointAdmin}list?${urlComplementar}`
    return http.get(url).then(data => data.data)
  }

  const getUser = urlComplementar => {
    // eslint-disable-next-line no-param-reassign
    urlComplementar = urlComplementar === undefined ? '' : urlComplementar
    const url = `${endpointAdmin}details?${urlComplementar}`
    return http.get(url).then(data => data.data)
  }

  return {
    get,
    getAdmin,
    getUser,
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  userService,
}
